<template>
  <main-layout itemMenuActive="14" tituloMenu="Cantidad Transacciones de Onboarding">
    <div class="container">
      <br />
      <div class="columns is-mobile is-multiline is-centered">
        <div class="column is-12">
          <div class="card">
            <div class="card-content">
              <div class="content">
                <div v-if="datosUsuario.length > 0">
                  <b-field grouped>
                    <b-field>
                      <p class="has-text-left subtitle">
                        Cantidad de Transacciones de Onboarding Acumuladas por Mes
                      </p>
                    </b-field>
                    <div class="uk-navbar-right">
                      <b-field label="">
                        <b-button v-on:click="exportExcel">Excel</b-button>
                        <b-button v-on:click="exportPDF">PDF</b-button>
                      </b-field>
                    </div>
                  </b-field>
                  <grafico-area
                    :dataInfo="datosUsuario"
                    :nombre1="'Cantidad'" :nombre2="'Null'" :nombre3="'Acumulado'"
                  ></grafico-area>
                </div>
                <div v-else>
                  <b-message type="is-info">
                    Sin datos para procesar.
                  </b-message>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="true"
    ></b-loading>
  </main-layout>
</template>

<script>
import MainLayout from "@/components/Layout/MainLayout";
import GraficoArea from "@/components/Graficos/GraficoArea";
import {mapActions, mapGetters, mapState} from "vuex";
import {saveLog} from "../../services/exportLogs";

export default {
  name: "TransaccionesOnboardingAcomulado",
  components: { MainLayout, GraficoArea },
  data() {
    return {
      isLoading: false,
      cantidad: null,
      datosUsuario: [],
    };
  },
  mounted() {
    this.obtenerDatos();
  },
  methods: {
    obtenerDatos() {
      if (this._PERFIL_MODULO_.onboarding) {
        this.isLoading = true;

        const options = {
          method: "get",
          uri: "boards/getCounterTransactionMonths",
        };

        this.datosUsuario = [];

        this._axios(options)
          .then((response) => {
            this.datosUsuario = response.data;
          })
          .catch((error) => {
            this.isLoadingTable = false;
            console.log(error);
          })
          .finally(() => this.cancelarLoading());
      } else {
        this.$router.push("login");
      }
    },
    exportExcel: function () {
      let routeData = `${this._HOST_SERVE}/export/exportExcelAcomuladoPorMesOnboarding`;
      window.open(routeData, "_blank");
      this.saveExportEvent("excel")
    },
    exportPDF: function () {
      let routeData = `${this._HOST_SERVE}/export/exportPDFAcomuladoPorMesOnboarding`;
      window.open(routeData, "_blank");
        this.saveExportEvent("pdf")
    },
    alertCustomError(msj, titulo = "Error", tipo = "is-danger") {
      this.$buefy.dialog.alert({
        title: titulo,
        message: msj,
        type: tipo,
        hasIcon: true,
        icon: "times-circle",
        iconPack: "fa",
        ariaRole: "alertdialog",
        ariaModal: true,
      });
    },
    cancelarLoading() {
      setTimeout(() => {
        this.isLoading = false;
      }, 1500);
    },
      saveExportEvent(type) {
          let logData = {
              "user": this.getUsuario(),
              "sectionId": 1,
              "reportId": 10,
              "format": type,
          }
          saveLog(logData);
      },
    ...mapActions(["_axios"]),
      ...mapGetters(["getUsuario"])
  },
  computed: {
    ...mapState(["_PERFIL_MODULO_", "_HOST_SERVE"]),
  },
};
</script>